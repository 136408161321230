<template>
	<div class="app-container">
		<div class="filter-container">
			<!-- 搜索 -->
			<div class="filter-item">
				<label class="label">广告位置:</label>
				<el-select v-model="adPositionId" placeholder="请选择广告位置">
					<el-option label="全部" :value="0"></el-option>
					<el-option
						v-for="item in adPosition"
						:key="item.value"
						:label="item.label"
						:value="item.value"
					></el-option>
				</el-select>
			</div>
			<div class="filter-item">
				<label class="label">起止时间:</label>
				<el-date-picker
					v-model="searchTime"
					type="datetimerange"
					range-separator="～"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
					align="right"
					value-format="yyyy-MM-dd HH:mm:ss"
				></el-date-picker>
			</div>
			<div class="filter-item">
				<el-button type="primary" style="margin:0 20px;width:90px;" @click="getAdList">查询</el-button>
				<buttonPermissions :datas="'adManageDetial'">
					<el-button type="primary" style="width:90px;" @click="toAdDetial()">添加</el-button>
				</buttonPermissions>
			</div>
		</div>
		<!-- <div class="filter-container">
			<div class="filter-item">
				<el-button type="primary" style="margin-left:30px;width:90px;" @click="getAdList">查询</el-button>
				<el-button type="primary" style="width:100px;" @click="toAdDetial()">添加</el-button>
			</div>
		</div>-->
		<!-- 表格 -->
		<div class="table-container">
			<el-table :data="adPositionList" style="width: 100%;margin-bottom: 20px;" row-key="id" border>
				<el-table-column prop="bannerName" label="广告名称" width="120"></el-table-column>
				<el-table-column label="略缩图">
					<template slot-scope="scope">
						<img
							:src="scope.row.bannerImgUrl+'?x-oss-process=image/resize,m_fixed,h_80,w_80'"
							style="width:50px;height:50px;"
							v-if="scope.row.bannerType == 3 && scope.row.bannerImgUrl"
						/>
						<img
							:src="scope.row.bannerImgUrl+'?x-oss-process=image/resize,m_fixed,h_80,w_140'"
							style="width:140px;height:50px;"
							v-if="scope.row.bannerType != 3 && scope.row.bannerImgUrl"
						/>
					</template>
				</el-table-column>
				<el-table-column prop="realName" label="创建人"></el-table-column>
				<el-table-column prop="authority" label="广告位置" width="100">
					<template slot-scope="scope">
						<span v-for=" ad in adPosition" :key="ad.value">
							<span v-if="scope.row.bannerType == ad.value">{{ad.label}}</span>
						</span>
					</template>
				</el-table-column>
				<el-table-column prop="begTime" label="开始日期" width="100"></el-table-column>
				<el-table-column prop="endTime" label="结束日期" width="100"></el-table-column>

				<el-table-column prop="address" label="操作" width="320">
					<template slot-scope="scop">
						<div style="display:flex;">
							<buttonPermissions :datas="'adManageEdit'">
								<div class="table-button" @click="toAdDetial(scop.row.id)">编辑</div>
							</buttonPermissions>
							<buttonPermissions :datas="'adManageDel'">
								<div class="table-button" style="color:#F56C6C" @click="deleteItem(scop.row.id)">删除</div>
							</buttonPermissions>
						</div>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination
				v-if="total"
				style="margin:20px 0;float:right;"
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
				:current-page="pageNo"
				:page-sizes="[10, 20, 30, 40, 50]"
				:page-size="pageSize"
				layout="total, sizes, prev, pager, next, jumper"
				:total="total"
			></el-pagination>
		</div>
	</div>
</template>

<script>
import {
	getAdPositionList,
	delAdPosition
} from '@/api/TurnTomySelf.js'
import buttonPermissions from '@/components/buttonPermissions';
import adConfig from '@/assets/js/ad-config.js'
import config from '@/config/index'
export default {
    name: 'adManage',
	components: {
		buttonPermissions
	},
	data () {
		return {
			adPositionId: '',   //广告位置id
			adPosition: adConfig.adPosition,    //广告位置列表
			pageNo: 0,
			pageSize: 20,
			total: 0,
			searchTime: '',  //搜索时间
			adPositionList: [],  //广告位置列表数据
			imgUrl: config.IMG_BASE,
		};
	},
	created () {
		this.getAdList();
	},
	methods: {
		//获取广告位置列表
		async getAdList () {
			let data = {
				pageNo: this.pageNo,
				pageSize: this.pageSize,

			}
			if (this.searchTime) {
				console.log(this.searchTime);
				data.begTime = this.searchTime[0];
				data.endTime = this.searchTime[1];
			}
			if (this.adPositionId != 0) {
				data.bannerType = this.adPositionId;
			}
			let res = await getAdPositionList(data);
			this.adPositionList = res.data.records;
			this.total = res.data.total;
		},
		//添加广告位
		toAdDetial (id) {
			let temp = id ? ('?id=' + id) : '';
			this.$router.push({ path: '/setting/adManage/adManageDetial' + temp })
		},
		// 切换显示条数
		handleSizeChange (val) {
			this.pageSize = val;
			this.getAdList();
		},
		// 翻页
		handleCurrentChange (val) {
			this.pageNo = val;
			this.getAdList();
		},
		//删除
		deleteItem (id) {
			console.log(id);
			this.$confirm('删除后将无法恢复, 是否继续?', '删除菜单', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(async () => {
				let result = await delAdPosition({
					id: id
				});
				if (result.success) {
					this.$message({
						type: 'success',
						message: '删除成功!'
					});
				} else {
					this.$message({
						type: 'error',
						message: result.alertMsg
					});
				}
				this.getAdList();
			}).catch(() => {
				console.log('取消删除');
			});
		},
	},
};
</script>

<style lang="less" scoped>
.table-button {
	color: #409eff;
	display: inline-block;
	margin-right: 20px;
	cursor: pointer;
}
</style>