/*广告类数据配置*/


const adPosition = [{
        value: 1,
        label: '首页'
    },
    // {
    //     value: 2,
    //     label: '活动'
    // },
    // {
    //     value: 3,
    //     label: '商品'
    // },
    {
        value: 4,
        label: '直播'
    },
    // {
    //     value: 5,
    //     label: '限时团品'
    // },
    // {
    //     value: 6,
    //     label: '首页弹窗'
    // },
    // {
    //     value: 7,
    //     label: '省钱账单'
    // },
    {
        value: 8,
        label: '个人中心'
    },
];


export default {
    adPosition
};